import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <div className="about-text">
        <h1>About Me</h1>
        <p>
          Hello! I'm Pathum Vimukthi, a Computer Engineering student at the Faculty of Engineering, University of Ruhuna.
          I'm passionate about cybersecurity and continually seek to expand my knowledge and skills in this field.
          I enjoy exploring new developments in technology and am particularly interested in how cybersecurity shapes and secures our digital world.
        </p>
        <Link to="/skills" className="skills-button">
          Go to Skills
        </Link>
      </div>
      <div className="about-photo">
      <img src={`${process.env.PUBLIC_URL}/assets/pathum2.png`} alt="Pathum Vimukthi" />

       {/* Reference the image in the public folder */}
      </div>
    </div>
  );
};

export default About;
