// src/components/Skills.js
import React from 'react';
import './Skills.css';

const skills = [
  { name: 'HTML', percentage: 70 },
  { name: 'JavaScript', percentage: 30 },
  { name: 'CSS', percentage: 70 },
  { name: 'Python', percentage: 30 },
  { name: 'React', percentage: 30 },
  { name: 'C', percentage: 50 },
  { name: 'C++', percentage: 60 },
   { name: 'GitHub', percentage: 30 },
];

const getSkillLevel = (percentage) => {
  if (percentage <= 35) return 'Beginner';
  if (percentage <= 50) return 'Intermediate';
  if (percentage <= 75) return 'Advanced';
  return 'Expert';
};

const Skills = () => {
  return (
    <div className="skills-page">
      <h1>My Skills</h1>
      <div className="skills-container">
        {skills.map((skill, index) => (
          <div className="skill" key={index}>
            <p>{skill.name}</p>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${skill.percentage}%` }}>
                <span className="progress-text">{getSkillLevel(skill.percentage)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
