// src/components/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      {/* Profile Content */}
      <div className="profile-section">
        <img src={`${process.env.PUBLIC_URL}/assets/pathum3.jpg`} alt="Profile" className="profile-photo" />
        <h1>Hello, I'm Pathum Vimukthi</h1>
        <p>
          I'm a Computer Engineering student at the Faculty of Engineering, University of Ruhuna. 
        </p>
        
        {/* Link styled as a button */}
        <Link to="/about" className="learn-more-link">
          Learn More About Me
        </Link>
      </div>
    </div>
  );
};

export default Home;
