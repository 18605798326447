// src/components/Certificate.js
import React from 'react';
import './Certificate.css';

const certificates = [
  {
    title: 'Introduction to CyberSecurity',
    description: 'Completed the Course and passed all assessments',
    image: `${process.env.PUBLIC_URL}/assets/Introduction_to_Cybersecurity_Badge_page-0001.jpg`,
    date: 'September 2024',
    issuer: 'Cisco',
  },
  {
    title: 'Photoshop',
    description: 'Completed a course on Photoshop for beginners',
    date: 'August 2024',
    issuer: 'Udemy',
    image: `${process.env.PUBLIC_URL}/assets/Photoshop.jpeg`,
  },
  {
    title: 'Applied ChatGPT for Cybersecurity',
    description: 'Completed a course on using ChatGPT for cybersecurity applications',
    date: 'July 2024',
    issuer: 'Coursera',
    image: `${process.env.PUBLIC_URL}/assets/gpt.png`,
  },

];

const competitions = [
  {
    title: 'MoraXtreme',
    description: 'Secured 53rd place out of over 380 teams',
    date: 'October 2024',
    issuer: 'Moratuwa IEEE Student Branch',
    image: `${process.env.PUBLIC_URL}/assets/TargaryenX - Pathum Vimukthi.jpg`,
  },
  // {
  //   title: 'VoltCast 1.O',
  //   description: '2nd Runner-up ',
  //   date: 'Sep 2024',
  //   issuer: 'IEEE Student Branch of University of Ruhuna',
  //   image: `${process.env.PUBLIC_URL}/assets/Voltcast.jpg`,
  // },
  // {
  //   title: 'Solid Works Challenge',
  //   description: 'Participated in a Solid Works design competition',
  //   date: 'April 2024',
  //   issuer: 'Mechanical Engineering Society',
  //   image: `${process.env.PUBLIC_URL}/assets/solidworks.jpg`,
  // },
 
];

const Certificate = () => {
  return (
    <div className="certificate-page">
      <h1>Certificates</h1>

      {/* Certificates Section */}
      <div className="certificate-section">
        <h2>Education</h2>
        <div className="certificate-container">
          {certificates.map((item, index) => (
            <div className="certificate-block" key={index}>
              <img
                src={item.image}
                alt={`${item.title} Certificate`}
                className="certificate-image"
              />
              <h3 className="certificate-title">{item.title}</h3>
            
              <p className="certificate-description">{item.description}</p>
              <div className="certificate-details">
                <span className="certificate-date">{item.date}</span>
                <span className="certificate-issuer">{item.issuer}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Competitions Section */}
      <div className="certificate-section">
        <h2>Competitions</h2>
        <div className="certificate-container">
          {competitions.map((item, index) => (
            <div className="certificate-block" key={index}>
              <img
                src={item.image}
                alt={`${item.title} Certificate`}
                className="certificate-image"
              />
              <h3>{item.title}</h3>
              <p className="certificate-description">{item.description}</p>
              <div className="certificate-details">
                <span className="certificate-date">{item.date}</span>
                <span className="certificate-issuer">{item.issuer}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certificate;
